<template>
	<div>
		<button @click="returnToDesktop" id="mazeReturn">Return to Desktop</button>
		<div class="gameInfoText">Use 'WADS' keys to move</div>
		<div id="canvas-container">
			<canvas id="mazeCanvas"></canvas>
		</div>
	</div>
</template>
  
<script>
import { User } from './User';



export default {
	name: "RayCastingMaze",
	components: {
	},
	data() {
		return {
		}
	},
	mounted() {
		const canvas = document.getElementById('mazeCanvas');
		const ctx = canvas.getContext('2d');
		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;
		
		const user = new User(canvas.width, canvas.height, ctx);
		console.log(user);
		// function animate() {
		// 	// ctx.clearRect(0,0,canvas.width, canvas.height);
		// 	// maze.drawMaze();
		// 	requestAnimationFrame(animate)
		// }
		// animate();
	},
	methods: {
		returnToDesktop() {
			this.$router.push("/");
		}
	}
};
</script>
<style>
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#canvas-container {
  display: flex;
  justify-content: center;
}

#mazeCanvas {
  position: absolute;
  width: 80%;
  max-width: 1000px; /* Set a maximum width for the canvas, adjust as needed */
}

#mazeReturn {
	position: relative;
	z-index: 1000;
}
.gameInfoText {
	color: white;
}

</style>